import React, {useState, useContext, useEffect, Fragment} from "react";
import {AppContext} from "../../helpers/AppContext";
import {getFormattedTime} from "../../helpers/helpers";
import {CSVLink} from "react-csv";
import loadingGif from "../../assets/Loading.png";
import makeRequest from "../../helpers/DataRequester";
import URL_ROUTES from "../../constants";
import moment from 'moment-timezone';

export default function Residents() {
    const [residents, setResidents] = useState([]);
    const [CSVdata, setCSVdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const {chosenLocationId, chosenLocationName, inputsTable, LocationDetails} = useContext(AppContext);
    const [email, setEmail] = useState("");
    const [deadLine, setDeadLine] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [showSendEmail, setShowSendEmail] = useState(false);

    useEffect(() => {
        if (chosenLocationId !== "") {
            getData();
        }
    }, [chosenLocationId]);

    const getData = async () => {
        setLoading(true);
        if (chosenLocationId !== "") {
            try {
                let url_routes = LocationDetails.enableInduction ? URL_ROUTES.GET_INDUCTION_DATA : URL_ROUTES.GET_RESIDENTS_DATA;
                let url = `${url_routes}?chosenLocationId=${chosenLocationId}`;
                const response = await makeRequest(url, "GET")
                if (response) {
                    let residents = response.map((resident) => {
                        const convertedTime = getFormattedTime(resident.time, LocationDetails.locationTimeZone)
                        const deadLineDate = resident.deadlineDate ? getFormattedTime(resident.deadlineDate, LocationDetails.locationTimeZone) : "";
                        const expiryDate = resident.expiryDate ? getFormattedTime(resident.expiryDate, LocationDetails.locationTimeZone) : "";
                        return {
                            name: resident.name,
                            unit: resident.unit,
                            mobile: resident.mobile,
                            email: resident.email,
                            resident: resident.resident,
                            companyName: resident.companyName,
                            purposeOfVisit: resident.purposeOfVisit,
                            visitDuration: resident.visitDuration,
                            time: convertedTime,
                            answer: resident.answer,
                            status: resident.status,
                            deadlineDate: deadLineDate,
                            expiryDate: expiryDate
                        }
                    });

                    setResidents(residents);
                    setCSVdata(residents);
                }
            } catch (error) {
                console.error("Error getting message:", error);
                setWarningMessage(error.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSendEmailClick = () => {
        setShowSendEmail(true);
    };
    const  handleSendEmailSubmit = async (e) => {
        e.preventDefault();
        try {
            await makeRequest(URL_ROUTES.SEND_INDUCTION_EMAIL, "POST", {}, {
                emailAddresses: email.split(","),
                deadlineDate: deadLine,
                expiryDate: expiryDate,
                locationId: chosenLocationId,
                locationURL: LocationDetails.url
            }, false);

            await getData();

            alert("Email sent successfully")
        } catch (error) {
            console.log("something wrong with email sending")
        }
        // Reset form and close popup
        setEmail("");
        setDeadLine("");
        setExpiryDate("");
        setShowSendEmail(false);
    };

    return (
        <Fragment>
            <div className="components-container">
                <div className="csv-container csv-container-residents">
                    <div className="residents-header">
                        <h2 className="questions-title">Review {LocationDetails.enableInduction ? "Inductions" : "Residents"}</h2>
                        <p className="location-name">{chosenLocationName}</p>
                    </div>
                    {loading ? (
                        <img className="loading" src={loadingGif} alt="Loading is here"/>
                    ) : null}

                    <div>
                        <CSVLink className="portal-btn-csv" data={CSVdata}>
                            Export CSV
                        </CSVLink>
                        {LocationDetails.enableInduction && (
                            <button className="portal-btn-induction" onClick={handleSendEmailClick}>
                                Send Induction Email
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className="resident-container-div">
                <table className="resident-container">
                    <thead>
                    <tr className="resident-title">
                        {inputsTable?.name && <th>Name</th>}
                        {inputsTable?.unit && <th>Apt No.</th>}
                        {inputsTable?.mobile && <th>Mobile</th>}
                        {inputsTable?.email && <th>Email</th>}
                        {inputsTable?.resident && <th>Resident</th>}
                        {inputsTable?.companyName && <th>Company Name</th>}
                        {inputsTable?.purposeOfVisit && <th>Purpose of Visit</th>}
                        {inputsTable?.visitDuration && <th>Visit Duration</th>}
                        <th>Timestamp</th>
                        <th>Answer</th>
                        {LocationDetails?.enableInduction && <th>Induction Deadline</th>}
                        {LocationDetails?.enableInduction && <th>Induction Expiry</th>}
                        {LocationDetails?.enableInduction && <th>Status</th>}
                    </tr>
                    </thead>
                    <tbody>
                    {residents.map((resident, index) => (
                        <tr key={index} className="resident-data">
                            {inputsTable?.name && (
                                <td className="data-name">{resident.name}</td>
                            )}
                            {inputsTable?.unit && <td>{resident.unit}</td>}
                            {inputsTable?.mobile && <td>{resident.mobile}</td>}
                            {inputsTable?.email && <td>{resident.email}</td>}
                            {inputsTable?.resident && <td>{resident.resident}</td>}
                            {inputsTable?.companyName && <td>{resident.companyName}</td>}
                            {inputsTable?.purposeOfVisit && <td>{resident.purposeOfVisit}</td>}
                            {inputsTable?.visitDuration && <td>{resident.visitDuration}</td>}
                            <td className="data-time">{resident.time}</td>
                            <td>{resident?.answer || " "}</td>
                            {LocationDetails.enableInduction && <td>{resident?.deadlineDate || ""}</td>}
                            {LocationDetails.enableInduction && <td>{resident?.expiryDate || ""}</td>}
                            {LocationDetails.enableInduction && <td>{resident?.status || ""}</td>}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {showSendEmail && <div className="add-printer-overlay">
                <div className="back-button-container">
                    <button className="back-button" onClick={() => setShowSendEmail(false)}>Close</button>
                </div>
                <form className="form settings-form" name="settings-form" onSubmit={handleSendEmailSubmit}>
                    <div className="settings-div">
                        <div className="input-wrapper">
                            <input
                                placeholder="Email addresses (comma separated)"
                                type="text"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoComplete="off"
                                required
                            />
                        </div>
                        <div className="input-wrapper">
                            <label htmlFor="deadline-date">Deadline Date</label>
                            <input
                                type="date"
                                value={deadLine}
                                onChange={(e) => setDeadLine(e.target.value)}
                                required
                            />
                        </div>
                        {/*    <div className="input-wrapper">*/}
                        {/*        <label htmlFor="deadline-date">Expiry Date:</label>*/}
                        {/*    <input*/}
                        {/*        type="date"*/}
                        {/*        value={expiryDate}*/}
                        {/*        onChange={(e) => setExpiryDate(e.target.value)}*/}
                        {/*        required*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <button type="submit" className="settings-save-btn">Send</button>
                    </div>
                </form>
            </div>
            }

        </Fragment>
    );
}
