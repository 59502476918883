import React, { useEffect, useState, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Locations from "./portal/Locations";
import Residents from "./portal/Residents";
import RulesEdit from "./portal/RulesEdit.jsx";
import QuestionsEdit from "./portal/QuestionsEdit";
import Message from "./portal/Message";
import Warning from "./portal/Warning";
import { AppContext } from "../helpers/AppContext";
import { AuthService } from "../helpers/Auth";
import "../styles/components/portal.scss";
import makeRequest from "../helpers/DataRequester";
import URL_ROUTES from "../constants";
import UserManagement from "./portal/UserManagement";
import Reporting from "./portal/Reporting";

export default function Portal() {
    const navigate = useNavigate();
    const {LocationDetails} = useContext(AppContext);

    const [components, setComponents] = useState({
        locations: true,
        rules: false,
        questions: false,
        message: false,
        warning: false,
        analytics: false,
    });

    const { currentUser } = useContext(AppContext);

    useEffect(() => {
        const user = AuthService.getUserDetails();
        currentUser["uid"] = user.claims.uid;
        currentUser["role"] = user.claims.role;
        currentUser["email"] = user.claims.email;
        currentUser["displayName"] = user.claims.displayName;

        //if role is super_admin then push settings: false, to the components object
        if (currentUser.role === "super_admin") {
            const newComponents = { ...components };
            newComponents["settings"] = false;
            setComponents(newComponents);
        }

        // if (currentUser.role === "super_admin") {
        //     const newComponents = { ...components };
        //     // newComponents["reporting"] = false;
        //     setComponents(newComponents);
        // }
    }, [])

    useEffect(() => {
        if (LocationDetails.enableInduction) {
            setComponents({
                locations: true,
                "Induction History": false,
                rules: false,
                questions: false,
                message: false,
                warning: false,
                analytics: false,
            })
        } else {
            setComponents({
                locations: true,
                residents: false,
                rules: false,
                questions: false,
                message: false,
                warning: false,
                analytics: false,
            })
        }
    }, [LocationDetails])

    const toggleComponent = (component) => {
        const newComponents = { ...components };
        for (const key in newComponents) {
            newComponents[key] = key === component;
        }
        setComponents(newComponents);
    };

    const logout = async () => {

        try {
            const response = await makeRequest(URL_ROUTES.LOGOUT, "POST", {}, null, false);
            if (response.status === 200) {
                AuthService.removeToken();
                setTimeout(() => {
                    navigate('/login');
                }, 1000);
            } else {
                console.log(response.message);
            }
        } catch (error) {
            console.error("Error logging out:", error);
        }

    };

    const activeNavStyle = {
        color: "#fff",
        backgroundColor: "#f66d51",
    };

    return (
        <Fragment>
            <div className="nav-container">
                {Object.keys(components).map((key) => (
                    <p
                        key={key}
                        style={components[key] ? activeNavStyle : null}
                        onClick={() => toggleComponent(key)}
                    >
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                    </p>
                ))}
                <p onClick={logout}>Log Out</p>
            </div>

            <div className="portal-container">
                {Object.keys(components).map((key) => (
                    components[key] && React.createElement({
                        locations: Locations,
                        residents: Residents,
                        "Induction History": Residents,
                        rules: RulesEdit,
                        questions: QuestionsEdit,
                        message: Message,
                        settings: UserManagement,
                        warning: Warning,
                        analytics: Reporting,
                    }[key])
                ))}
            </div>
        </Fragment>
    );
}
