import React, { createContext, useState, useContext } from 'react';

const LoadingContext = createContext(null);

export const LoadingProvider = ({ children }) => {
    const [apiCallInProgress, setApiCallInProgress] = useState(false);

    return (
        <LoadingContext.Provider value={{ apiCallInProgress, setApiCallInProgress }}>
            {children}
        </LoadingContext.Provider>
    );
};

export const useLoading = () => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }
    return context;
};
