import { AuthService } from "./Auth";

/**
 *
 * @param {string} endpoint The endpoint to make the request to
 * @param {string} method The HTTP method to use
 * @param {Object} headers The headers to send with the request
 * @param {Object} body The body to send with the request
 * @param {boolean} skipAuth Whether or not to skip authentication
 * @returns {Promise<Object>} The response data
 * @throws {Error} An error object with the details of the request
 */
export default async function makeRequest(endpoint, method = 'GET', headers = {}, body = null, skipAuth = false, skipJSON = false) {
    try {
        // REACT_API_GATEWAY can be grabbed from env file (could be host:port)
        let url = `/api/${endpoint}`;

        //if skipJSON is true  then we dont add 'Content-Type': 'application/json', to headers
        if (!skipJSON) {
            headers = {
                'Content-Type': 'application/json',
                ...headers,
            };
        } else {
            headers = {
                ...headers,
            };
        }

        if (!skipAuth) {
            // Check if the user is authenticated using AuthService
            const isAuthenticated = AuthService.isAuthenticated();

            if (!isAuthenticated) {
                // Handle the case where the user is not authenticated
                throw new Error('User is not authenticated');
            }
            headers = {
                'Authorization': `Bearer ${AuthService.getToken()}`,
                ...headers,
            };
        }

        const response = await fetch(url, {
            method,
            headers,
            body: body ? skipJSON ? body : JSON.stringify(body) : null,
        });

        if (!response.ok) {
            if (response.status === 401) {
                return {
                    status: 401,
                    message: 'Incorrect username or password',
                };
            }
            return {
                status: response.status,
                message: response.statusText,
            }
        }

        if (response.headers.get('Content-Type') === 'application/pdf') {
            return response;
        }

        const data = await response.json();
        
        return data;
    } catch (error) {
        console.error('Request failed:', error);
        return error;
    }
};
