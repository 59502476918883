import React, {useContext, useState, useEffect, Fragment} from "react";
import {AppContext} from "../../helpers/AppContext";
import loadingGif from "../../assets/Loading.png";
import makeRequest from "../../helpers/DataRequester";
import URL_ROUTES from "../../constants";

const Message = () => {
    const [messages, setMessages] = useState([
        {
            id: "",
            content: "",
            type: "message"
        },
        {
            id: "",
            content: "",
            type: "thankYouMessage"
        }
    ]);
    const [loading, setLoading] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const {chosenLocationId, chosenLocationName} = useContext(AppContext);

    useEffect(() => {
        const fetchMessages = async () => {
            if (chosenLocationId !== "") {
                setLoading(true);
                try {
                    let url = `${URL_ROUTES.GET_COLLECTION}?locationId=${chosenLocationId}&collectionType=message`;
                    const response = await makeRequest(url, "GET");

                    // Use the spread operator to merge the arrays
                    const mergedList = messages.map((obj, index) => ({
                        ...obj,
                        ...response[index],
                        type: obj.type
                    }));
                    
                    setMessages(mergedList);
                    setIsButtonDisabled(false);
                } catch (error) {
                    setInfoMessage("Something went wrong. Please try again later.");
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchMessages();
    }, [chosenLocationId]);

    const handleEdit = (value, id, type = 'message') => {
        const updatedMessages = messages.map((msg) => {
            if (msg.id === id) {
                return {
                    ...msg,
                    content: value,
                    type: type
                };
            }
            return msg;
        });
        setMessages(updatedMessages);
    };

    const handleUpload = async () => {
        setLoading(true);
        try {
            let url = `${URL_ROUTES.SAVE_TO_COLLECTION}?locationId=${chosenLocationId}&collectionType=message`;
            const response = await makeRequest(url, "POST", {}, {dataList: messages});
            setInfoMessage(response.message);
        } catch (error) {
            setInfoMessage(error.message);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <div className="components-container">
                <div className="csv-container">
                    {loading && <img className="loading" src={loadingGif} alt="Loading is here"/>}
                    <div>
                        <h2>Update Message</h2>
                        {infoMessage && <p className="info-message">{infoMessage}</p>}
                    </div>
                    <div>
                        <button className="save-btn" onClick={handleUpload} disabled={isButtonDisabled}>
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <div className="edit-container">
                {messages.map((msg) => (
                    <>
                        <p>
                            <span className="location-name">{chosenLocationName} : {msg.type == 'message' ? 'Welcome' : 'Thank You'}</span>
                        </p>
                        <div key={msg.id} className="textarea-container">
                        <textarea
                            placeholder="Type the message and click save"
                            name={msg.type}
                            value={msg.content}
                            onChange={(e) => handleEdit(e.target.value, msg.id, msg?.type)}
                        ></textarea>
                        </div>
                    </>
                ))}
            </div>
        </Fragment>
    );
};

export default Message;
