import React, { useContext, useState, useEffect, Fragment } from "react";
import { AppContext } from "../../helpers/AppContext";
import loadingGif from "../../assets/Loading.png";
import makeRequest from "../../helpers/DataRequester";
import URL_ROUTES from "../../constants";

export default function Warning() {
    const { chosenLocationId, chosenLocationName } = useContext(AppContext);
    const [warnings, setWarnings] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");

    useEffect(() => {
        const fetchWarning = async () => {
            if (chosenLocationId !== "") {
                setLoading(true);
                try {
                    let url = `${URL_ROUTES.GET_COLLECTION}?locationId=${chosenLocationId}&collectionType=warning`;
                    const response = await makeRequest(url, "GET");
                    setWarnings(response);
                    setIsButtonDisabled(false);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchWarning();
    }, [chosenLocationId]);

    const handleChange = (value, id) => {
        const updatedWarnings = warnings.map((warning) => {
            if (warning.id === id) {
                return { ...warning, content: value };
            }
            return warning;
        });
        setWarnings(updatedWarnings);
    };

    const handleUpload = async () => {
        setLoading(true);
        try {
            let url = `${URL_ROUTES.SAVE_TO_COLLECTION}?locationId=${chosenLocationId}&collectionType=warning`;
            const response = await makeRequest(url, "POST", {}, { dataList: warnings });
            setInfoMessage(response.message);
        } catch (error) {
            console.error(error);
            setInfoMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <div className="components-container">
                <div className="csv-container">
                    <div>
                        <h2>Review Warnings</h2>
                        <p className="location-name">{chosenLocationName}</p>
                        {infoMessage && <p className="info-message">{infoMessage}</p>}
                    </div>
                    {loading && <img className="loading" src={loadingGif} alt="Loading is here" />}
                    <div>
                        <button className="save-btn" onClick={handleUpload} disabled={isButtonDisabled}>
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <div className="edit-container">
                {warnings.map((warning) => (
                    <div key={warning.id} className="textarea-container">
                        <textarea
                            placeholder="Type the warning and click save"
                            name="warning"
                            value={warning.content}
                            onChange={(e) => handleChange(e.target.value, warning.id)}
                        ></textarea>
                    </div>
                ))}
            </div>
        </Fragment>
    );
}
