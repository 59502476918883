import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AppContext } from '../helpers/AppContext';
import makeRequest from '../helpers/DataRequester';
import URL_ROUTES from '../constants';
import { uid } from 'uid';
import "../styles/components/waiver.scss";
import loadingGif from "../assets/Loading.png";

export default function Waiver() {
    const warningLogo = "data: image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAzCAYAAADVY1sUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKNSURBVHgB3ZrhcdswDIVfev1fbVBuEG9gdYJmA3uDdgOlE3QEeQN3A7kTxJ5A7AT2BqkQUxfakUUQBGUn3x1Oio8C8ESIpKjcQZ+is7Kzr+44c79vnf11xwMUuUMaQ0kb5rUWiuJihKQkzcVCKO6SEEqaEr13xxL6SXOxOAradLbDBXEk5JaS5kJiLDxxJOQZH4BP+CB8Rj6ojvsSIAyOpVsgEy2O5aVl+86qkYSXGWK22kLIl0EYkyEunjKJKDtbu99bdz47E7NXit3ABdBwtvCSfBxp9+i1qzSF1AqO9l5yD4z2pWtbQKdXahp+LdLZeOc/GO0rd6SR7Q / SsSTkH9LZeuczRnu / jUU6VqtHfAqlNjG8CNni / bOjmf3gTHqX6Nqd9 / cKcdC1FvKFap//C9IheA09fgtzaDScGOhRCHOofCel0Ik2khzmvgPp3dBGksObZ7sROOFQ4nUmDxEbvxlyUgkchfAXpHQeGhlj4w+uIozA0RhLbmDIhZj+Qv9V1+J0zZTKl4HfNGd0/+3zDRX0esTgdGW7R3i4jom9GHMUO3qF8Jf03xntRWVFnO+i0FS/gR6HC+eprHBWVkPbQb9w+6y4DRvolBaV6twZ50HnxHxCBCXTqTbJD/kQnF7RRrU3ekqGY815wTDi3UNIHXCs9T5SIPxOVI85CH3ooQAtxu+8Rfp7f2hP2Hb2DYlxfiJuosphCyixAvB8JauhSF9iU4tokeEzhIHepjPHOItMMZx9XS17QGamePg5+8cqVEA2ERUmJoeYyXrinCV0BgDyscCVMUgbmulazqeISTCQ7R83yPi5OgUa0TilRm2u9jxwMRhfNa9x+//rcgLVPZWOX0ZzvGNIUHYB/wFuyIDNPWOITAAAAABJRU5ErkJggg==";
    const { location } = useParams();
    const navigate = useNavigate();
    const [rules, setRules] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const { returnUserData, returnUser, setReturnUserData, setReturnUser } = useContext(AppContext);
    const [signedWaiver, setSignedWaiver] = useState(false);

    useEffect(() => {
        managerReturnUserStorage();
    }, [returnUser]);

    function managerReturnUserStorage() {
        const storedRules = JSON.parse(sessionStorage.getItem("rules"));
        const storedQuestions = JSON.parse(sessionStorage.getItem("questions"));
        if (JSON.parse(localStorage.getItem("returnUserData"))) {
            const storedReturnUserData = JSON.parse(localStorage.getItem("returnUserData"));
            setReturnUserData(storedReturnUserData);
            setReturnUser(true);
        }

        if (storedRules) {
            setRules(storedRules);
        }
        if (storedQuestions) {
            setQuestions(storedQuestions);
        }

        if (returnUserData.additionalField) {
            const additionalField = returnUserData.additionalField;
            const purposeOfVisit = returnUserData.purposeOfVisit;
            if (additionalField.length > 0) {
                if (purposeOfVisit === "Other") {
                    let text = "Other, Text: " + additionalField;
                    setReturnUserData({ ...returnUserData, purposeOfVisit: text });
                }
                else {
                    let text = "A schedule meeting, Text: " + additionalField;
                    setReturnUserData({ ...returnUserData, purposeOfVisit: text });
                }
            }
        }
    }
    const submitWaiver = (e) => {
        const answer = sessionStorage.getItem("answer");
        if (answer === "Not accepted") {
            navigate(`/${location}/reject`);
        } else {
            handleSubmit(e);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (signedWaiver) {
            const response = await downloadPass();
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'PrintPass.pdf';
            link.click();
            navigate(`/${location}/thankyou`);
            return;
        }
        setLoading(true);

        try {
            const response = await makeRequest(URL_ROUTES.SUBMIT_DATA, "POST", {}, { location }, true);
            const { id, enableInduction } = response;
            console.log("tedts ttt")
            console.log(enableInduction)
            uploadData(id, enableInduction);
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const downloadPass = () => {
        setLoading(true);
        try {
            //get logo from local storage
            const logo = localStorage.getItem("clientLogo");
            //add it to the returnUserData
            const returnUserDataWithLogo = { ...returnUserData, logo };

            return makeRequest(URL_ROUTES.DOWNLOAD_PASS, "POST", {}, { returnUserData: returnUserDataWithLogo }, true);
        } catch (error) {
            setErrorMessage(error.message);
        }
        setLoading(false)
    }

    const uploadData = async (id, enableInduction) => {
        let customId = uid(20);
        localStorage.setItem("OrangePassCustomId", JSON.stringify(customId));
        const answer = sessionStorage.getItem("answer");
        console.log(enableInduction)
        console.log("testttt")
        try {
            let url = URL_ROUTES.UPLOAD_DATA
            if(enableInduction){
                url = URL_ROUTES.UPLOAD_INDUCTION
            }
            const response = await makeRequest(url, "POST", {}, { returnUserData, customId, locationId: id, answer }, true);
            setSignedWaiver(true);
        } catch (error) {
            setSignedWaiver(false);
            console.log(error);
            setErrorMessage(error.message);
        }
    };

    // Handle navigation back
    function backHandle() {
        navigate(-1);
    }

    const nonEmptyUserData = Object.fromEntries(
        Object.entries(returnUserData).filter(([key, value]) => value !== null && value !== "" && value !== undefined && key !== "agreed")
    );


    return (
        <div className='waiver-main-container'>
            <div className="back-button-container" style={{ height: "55px", marginTop: "0px", display: "flex", alignItems: "center", justifyContent: "center", }}>
                <div className="back-button" onClick={backHandle}>
                    Back
                </div>
            </div>

            {loading && <img className="loading" src={loadingGif} alt="Loading is here" />}

            <div className="rule-container" style={{ height: "calc(100% - 60px - 186px) !important", overflow: "auto !important", WebkitOverflowScrolling: "touch !important" }}>

                <div style={{ height: "100%", width: "100%" }}>
                    <div className="questions-title-wavier">Your waiver</div>
                    <div className="rules-questions-container"
                        style={{
                            height: "calc(100%-60px)", overflow: "auto"
                        }}>

                        <div className="user-wavier-container overflowing-element">
                            <div className='scrollingContent'>
                                <div className="questions-rules-title">Rules</div>
                                <div className="rules-data">
                                    <div className="full-width">
                                        <div className="smooth-dnd-container vertical">
                                            <div className="smooth-dnd-draggable-wrapper">
                                                <div className="smooth-dnd-container vertical">
                                                    <div className="cursor-pointer smooth-dnd-draggable-wrapper">
                                                        {rules.map((rule, index) => (
                                                            <div key={index} className="rules-questions-wavier">
                                                                <div className='index-no'>R{index + 1}</div>
                                                                <p>{rule.content}</p>&nbsp;
                                                            </div>
                                                        ))}
                                                        <div className="accept-rules-question">{sessionStorage.getItem("answer")}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="questions-rules-title">Questions and responses</div>
                                <div className="rules-data">
                                    <div className="full-width">
                                        <div className="smooth-dnd-container vertical">
                                            <div className="smooth-dnd-draggable-wrapper">
                                                <div className="smooth-dnd-container vertical">
                                                    <div className="cursor-pointer smooth-dnd-draggable-wrapper">
                                                        {questions.map((question, index) => (
                                                            <div key={index} className="rules-questions-wavier">
                                                                <div className='index-no'>Q{index + 1}</div>
                                                                <div className='inside-container'>
                                                                    <p>{question.content}</p>&nbsp;
                                                                    <div className='accept-rules-questions'>{question.answer}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="questions-rules-title">Your Information</div>
                                {returnUser && (
                                    <div className="residents-wavier-container">
                                        {Object.entries(nonEmptyUserData).map(([key, value]) => (
                                            <div className="residents-wavier" key={key}>
                                                <div className="residents-key">{key}: </div>
                                                <div className="residents-value">{value}</div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* footer container */}
            <div class="footer-content">
                <div class="footer-content-box">
                    <div class="lockicon wavier-lockicon wavier-lock-icon">
                        <img src={warningLogo} alt="Lock is here" />
                    </div>
                    <div class="wavier-footer">
                        <div>
                            <button
                                class="wavier-submit"
                                onClick={(e) => submitWaiver(e)}
                            >{!signedWaiver ? 'Sign and submit' : 'Download Pass'}</button>
                        </div>
                        <div>
                            <a class="website-link" href="https://www.orangesafepass.com" target="_blank" rel="noopener noreferrer">www.orangesafepass.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};