import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from "./serviceWorker";
import {BrowserRouter as Router} from "react-router-dom";
import {LoadingProvider} from "./helpers/LoadingContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <LoadingProvider>
            <Router>
                <App/>
            </Router>
        </LoadingProvider>
    </React.StrictMode>
);

serviceWorker.unregister();
