import React, { useEffect, useState } from 'react';
import logo from "../assets/OrangePass-Logo.png";
import "../styles/components/form.scss";
import { AuthService } from "../helpers/Auth";
import { useNavigate } from "react-router-dom";
import makeRequest from "../helpers/DataRequester";
import URL_ROUTES from "../constants";

export default function Login() {
    const navigate = useNavigate();

    function checkLogin() {
        if (AuthService.isAuthenticated()) {
            navigate('/portal');
        }
    }

    useEffect(() => {
        checkLogin();
    });

    const [errorMessage, setErrorMessage] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [processing, setIsProcessing] = useState(false)
    // const {setCurrentActiveLocation} = useContext(AppContext)

    function handleLogin(e) {
        setIsProcessing(true);
        e.preventDefault();

        makeRequest(URL_ROUTES.LOGIN, 'POST', {}, { email, password }, true).then((response) => {
            if (response.status === 200) {
                AuthService.setToken(response.token);
                navigate('/portal');
            } else {
                setIsProcessing(false);
                setErrorMessage(response.message);
            }
        });
    }


    return (
        <div>
            <div className="logo-container">
                <a
                    href="https://www.orangesafepass.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img className="logo" src={logo} alt="logo" />
                </a>
            </div>
            <div className="main-container">
                <form className="form" name="login" onSubmit={handleLogin}>
                    <h2>Login</h2>
                    <div className="input-wrapper">
                        <input
                            placeholder="Email"
                            type="email"
                            id="email"
                            name="email"
                            autoComplete="off"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>

                    <div className="input-wrapper">
                        <input
                            placeholder="Password"
                            type="password"
                            id="password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <a href="/resetpassword">Forgot password? </a>
                    <p className="error-message">{errorMessage}</p>
                    <button type="submit" disabled={processing}>
                        {processing ? 'Signing in...' : 'Log in'}
                    </button>
                </form>
            </div>
        </div>
    )
}