import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Pass from "./components/Pass";
import Portal from "./components/Portal";
import ProtectedRoutes from "./components/ProtectedRoutes";
import ResetPassword from "./components/ResetPassword";
import { AppContext } from "./helpers/AppContext";
import { LoadingProvider, useLoading } from "./helpers/LoadingContext";
import LoadingModal from "./components/LoadingModal";
import "./styles/styles.scss";
import Submit from "./components/Submit";
import Rules from "./components/Rules";
import Questions from "./components/Questions";
import Waiver from "./components/Waiver";
import Reject from "./components/Reject";
import ThankYou from "./components/ThankYou";
import NotFound from "./components/NotFound";

function App() {
  const [currentUser, setCurrentUser] = useState({});
  const [chosenLocationId, setChosenLocationId] = useState("");
  const [chosenLocationName, setChosenLocationName] = useState("");
  const [LocationDetails, setLocationDetails] = useState({});
  const [inputsTable, setInputsTable] = useState({
    name: true,
    unit: true,
    email: true,
    mobile: true,
    resident: true,
    companyName: true,
    purposeOfVisit: true,
    visitDuration: true,
  });
  const [progressBar, setProgressBar] = useState(35);
  const [returnUser, setReturnUser] = useState(false);
  const [returnUserData, setReturnUserData] = useState([]);

  const contextValues = {
    currentUser,
    setCurrentUser,
    chosenLocationId,
    setChosenLocationId,
    chosenLocationName,
    setChosenLocationName,
    LocationDetails,
    setLocationDetails,
    inputsTable,
    setInputsTable,
    progressBar,
    setProgressBar,
    returnUser,
    setReturnUser,
    returnUserData,
    setReturnUserData,
  };

  const { apiCallInProgress } = useLoading();

  return (
    <AppContext.Provider value={contextValues}>
      <LoadingModal show={apiCallInProgress} />
      <Routes>
        <Route index element={<Login />} />
        <Route exact path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/:location/pass" element={<Pass />} />
        <Route path="/:location/rules" element={<Rules />} />
        <Route path="/:location/thankyou" element={<ThankYou />} />
        <Route path="/:location/questions" element={<Questions />} />
        <Route path="/:location/userWaiver" element={<Waiver />} />
        <Route path="/:location/reject" element={<Reject />} />
        <Route path="/:location/submit" element={<Submit />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/portal" element={<Portal />} />
        </Route>
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
