import React, {useEffect, useState, useContext, Fragment} from "react";
import makeRequest from "../../helpers/DataRequester";
import URL_ROUTES, {generateUrl} from "../../constants";
import {AppContext} from "../../helpers/AppContext";
import loadingGif from "../../assets/Loading.png";
import {CSVLink} from "react-csv";
import moment from 'moment-timezone';
import {getFormattedTime} from "../../helpers/helpers";

export default function Reporting() {
    const {chosenLocationId, LocationDetails} = useContext(AppContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [ratingsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [CSVdata, setCSVdata] = useState([]);
    const [averageRatings, setAverageRatings] = useState({
        averageQuestionsPerRating: 0,
        averageRating: 0
    });
    const fetchRatings = async () => {
        try {
            setLoading(true);

            let url = generateUrl('LIST_RATINGS', {locationId: chosenLocationId});
            const response = await makeRequest(url, 'GET');

            if (response) {
                const updatedRatings = response.ratings.map(rating => {
                    rating.time = getFormattedTime(rating.time, LocationDetails.locationTimeZone)
                    return rating;
                });
                setCSVdata(updatedRatings);
                setAverageRatings({
                    averageQuestionsPerRating: response.averageQuestionsPerRating,
                    averageRating: response.averageRating
                });
            }
        } catch (error) {
            console.error(error);
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (chosenLocationId) {
            fetchRatings();
        }
    }, [chosenLocationId]);

    // Get current ratings based on pagination
    const indexOfLastRating = currentPage * ratingsPerPage;
    const indexOfFirstRating = indexOfLastRating - ratingsPerPage;
    const currentRatings = CSVdata?.slice(indexOfFirstRating, indexOfLastRating);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="settings-container">
            {errorMessage && <p className='error-message'>{errorMessage}</p>}
            {infoMessage && <p className='info-message'>{infoMessage}</p>}
            <br/>
            <div className='settings-header-container'>
                <h2>Analytics</h2>
                {loading ? (
                    <img className="loading" src={loadingGif} alt="Loading is here"/>
                ) : null}
                <CSVLink className="settings-btn-csv" data={CSVdata}>
                    Export CSV
                </CSVLink>
            </div>
            <table>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Question</th>
                    <th>Rating</th>
                </tr>
                {
                    currentRatings && currentRatings?.map((rating) => (
                        <tr key={rating.time} className='user-row'>
                            <td>{rating.time}</td>
                            <td>{rating.question}</td>
                            <td>{rating.rating}</td>
                        </tr>
                    ))
                }
                <tr>
                    <th></th>
                    <th></th>
                    <th>Average Rating</th>
                </tr>
                <tr className='user-row'>
                    <td></td>
                    <td></td>
                    {/*<td>{averageRatings?.averageQuestionsPerRating && averageRatings.averageQuestionsPerRating.toFixed(2)}</td>*/}
                    <td>{averageRatings.averageRating}</td>
                </tr>
                </thead>
                <tbody></tbody>
            </table>
            <ul>
                {CSVdata && CSVdata.length > 0 &&
                    Array.from({length: Math.ceil(CSVdata.length / ratingsPerPage)}).map((_, index) => (
                        <li key={index}>
                            <button onClick={() => paginate(index + 1)}>{index + 1}</button>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}
