import React, { useState, useEffect } from 'react';
import makeRequest from '../../helpers/DataRequester';
import { CSVLink } from "react-csv";
import URL_ROUTES from '../../constants';
import loadingGif from '../../assets/Loading.png';
import '../../styles/components/settings.scss';

export default function UserManagement() {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [infoMessage, setInfoMessage] = useState('');
    const [CSVdata, setCSVdata] = useState([]);

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await makeRequest(URL_ROUTES.LIST_USERS, 'GET');

            if (response) {
                let users = response.map((user) => ({
                    uid: user.uid,
                    email: user.email,
                    role: user.role,
                    admin: user.admin,
                    totalLocations: user.totalLocations,
                    status: user.status,
                    settings: user.settings
                }));

                setUsers(users);
                setCSVdata(users);
            }
        }
        catch (error) {
            console.error(error);
            setErrorMessage(error.message);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchUsers();
    }, []);

    // Get current users based on pagination
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = users?.slice(indexOfFirstUser, indexOfLastUser);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    /**
     * function to handle user status toggle
     * @param isActive
     * @param uid
     * @returns {Promise<void>}
     */
    const toggleUserStatus = async (isActive, uid) => {
        try {
            setLoading(true);
            const response = await makeRequest(`${URL_ROUTES.TOGGLE_USER_STATUS}`, 'POST', {}, { uid, status: isActive });
            setInfoMessage(response.message);

            // Update users state with modified data
            setUsers(prevUsers => prevUsers.map(user => user.uid === uid ? { ...user, status: isActive ? 'inactive' : 'active' } : user));
        }
        catch (error) {
            console.error(error);
            setErrorMessage(error.message);
        }
        finally {
            setLoading(false);
        }
    }

    /**
     * function to handle user deletion
     * @param uid
     * @returns {Promise<void>}
     */
    const handleDeleteUser = async (uid) => {
        try {
            setLoading(true);
            const response = await makeRequest(`${URL_ROUTES.DELETE_USER}`, 'DELETE', {}, { uid });
            setInfoMessage(response.message);

            // Update users state without the deleted user
            setUsers(prevUsers => prevUsers.filter(user => user.uid !== uid));
        }
        catch (error) {
            console.error(error);
            setErrorMessage(error.message);
        }
        finally {
            setLoading(false);
        }
    }

    /**
     * TODO: ENABLE THIS IF WE EVER DECIDE TO IMPLEMENT RME MODULE ENABLE/DISABLE ACCOUNT BASED RATHER THAN USER BASED
     * function to navigate to user management page
     * @param uid
     */
    async function enableRmeModule(uid, currentStatus) {
        const settings = {
            hasRmeSubscription: !currentStatus
        }
        try {
            setLoading(true);
            const response = await makeRequest(URL_ROUTES.TOGGLE_MODULE, "POST", {}, {
                uid,
                settings
            }, false, false);
            setInfoMessage(response.message);
            //update users state with modified data
            setUsers(prevUsers => prevUsers.map(user => user.uid === uid ? { ...user, settings: { hasRmeSubscription: !currentStatus } } : user));
        } catch (e) {
            console.error("Error enabling module:", e);
            setErrorMessage(e.message);
        }
        setLoading(false);
    }

    return (
        <div className="settings-container">
            {errorMessage && <p className='error-message'>{errorMessage}</p>}
            {infoMessage && <p className='info-message'>{infoMessage}</p>}
            <br />

            <div className='settings-header-container'>
                <h2>User Management Portal</h2>
                {loading ? (
                    <img className="loading" src={loadingGif} alt="Loading is here" />
                ) : null}
                <CSVLink className="settings-btn-csv" data={CSVdata}>
                    Export CSV
                </CSVLink>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>S.N</th>
                        <th>Email</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Total Locations</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentUsers && currentUsers.map((user, index) => (
                        <tr key={user.uid} className='user-row'>
                            <td>{index + 1}</td>
                            <td>{user.email}</td>
                            <td>{user.admin}</td>
                            <td>{user.role}</td>
                            <td>{user.totalLocations}</td>
                            <td className='user-button-container'>
                                {/* if user status is active, show deactivate button, else show activate button */}
                                {user.status === 'active' ?
                                    <button className='user-button deactivate-button' onClick={() => toggleUserStatus(true, user.uid)}>Deactivate</button> :
                                    <button className='user-button activate-button' onClick={() => toggleUserStatus(false, user.uid)}>Activate</button>
                                }
                                {/*<button className='user-button activate-button' onClick={() => enableRmeModule(user.uid, !!user?.settings?.hasRmeSubscription)}>{user?.settings?.hasRmeSubscription ? "Disable RME Module" : "Enable RME Module"}</button>*/}
                                <button className='user-button delete-button' onClick={() => handleDeleteUser(user.uid)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <ul>
                {users && users.length > 0 &&
                    Array.from({ length: Math.ceil(users.length / usersPerPage) }).map((_, index) => (
                        <li key={index}>
                            <button onClick={() => paginate(index + 1)}>{index + 1}</button>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}
