import moment from "moment-timezone";

/**
 * Get message from sessionStorage
 * @param type
 * @returns {*}
 */
const getMessage = (type = 'message') => {
    const sessionData = JSON.parse(sessionStorage.getItem('message'));
    //in message find the one which has type: welcome
    const savedMessage = sessionData?.find((item) => item.type === type);

    return savedMessage?.message;
}

/**
 * We save all the time in Sydney time
 * This function will convert the default timezone to the timezone send in params
 * @param timeStr
 * @param timeZone
 * @returns {string}
 */
const getFormattedTime = (timeStr, timeZone) => {
    const resultFormat = "DD-MM-YYYY HH:mm"
    const melbourneTimeZone = 'Australia/Melbourne';
    let convertedTime
    if (timeZone) {
        convertedTime = moment.tz(timeStr, melbourneTimeZone)
            .tz(timeZone)
            .format(resultFormat);
    } else {
        convertedTime = moment(timeStr).format(resultFormat)
    }
    return convertedTime
}

export {getMessage, getFormattedTime};