import React, { useContext, useState, useEffect, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../helpers/AppContext";
import Bar from "./Bar";
import "../styles/components/questions.scss";

export default function Questions() {
    const navigate = useNavigate();
    const { location } = useParams();
    const { setProgressBar } = useContext(AppContext);
    const [answerIsYes, setAnswerIsYes] = useState(true);
    const [questions, setQuestions] = useState([]);
    const [warning, setWarning] = useState([]);
    const [warningModal, setWarningModal] = useState(false);

    useEffect(() => {
        setProgressBar(65);
        setQuestions(JSON.parse(sessionStorage.getItem("questions")));
        setWarning(JSON.parse(sessionStorage.getItem("warning"))[0]);
    }, [setProgressBar]);

    const handleAnswer = (index, answer) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].answer = answer;
        setQuestions(updatedQuestions);
        sessionStorage.setItem("questions", JSON.stringify(updatedQuestions));
        if (answer === "no") {
            setAnswerIsYes(false);
        } else {
            setAnswerIsYes(true);
        }
    }

    const handleYesToAll = () => {
        const updatedQuestions = questions.map(question => ({ ...question, answer: "yes" }));
        setQuestions(updatedQuestions);
        sessionStorage.setItem("questions", JSON.stringify(updatedQuestions));
        setAnswerIsYes(true);
    }

    const handleNoToAll = () => {
        const updatedQuestions = questions.map(question => ({ ...question, answer: "no" }));
        setQuestions(updatedQuestions);
        sessionStorage.setItem("questions", JSON.stringify(updatedQuestions));
        setAnswerIsYes(false);
    }

    const handleSubmit = () => {
        const notAnswered = questions?.some(question =>
            !['yes', 'no'].includes(question.answer)
        );
        //if some question are answered yes, show warning modal
        const hasYes = questions?.some(question =>
            question.answer === "yes"
        );
        if (notAnswered) {
            alert("Please Answer All The Questions")
        } else if (hasYes) {
            setWarningModal(true);
        }
        else {
            navigate(`/${location}/submit`);
        }
    }

    const handleWarningSubmit = () => {
        navigate(`/${location}/submit`);
        setProgressBar(100);
    }

    // Handle navigation back
    function backHandle() {
        navigate(-1);
    }

    return (
        <Fragment>
            <Bar />
            <div className="main-container" style={{
                overflow: (warningModal === true) ? "hidden" : "auto"
            }}>
                <div className="back-button-container">
                    <div className="back-button" onClick={backHandle}>
                        Back
                    </div>
                </div>
                <div className="rules-questions-container">
                    <h2>Please answer the following</h2>
                    {questions.map((question, index) => (
                        <>
                            <div key={index} className="rules-questions" style={{ marginTop: "20px" }}>
                                <h1>Q{index + 1}</h1>
                                <p>{question.content}</p>
                            </div>
                            <div className="yes-no-button" style={{ marginTop: "-20px" }}>
                                <button
                                    className="yes-button"
                                    style={{
                                        border: question.answer === "yes" ? '1px solid orange' : '1px solid transparent'
                                    }}
                                    onClick={() => handleAnswer(index, "yes")}
                                >
                                    Yes
                                </button>
                                <button
                                    className="no-button"
                                    style={{
                                        border: question.answer === "no" ? '1px solid orange' : '1px solid transparent'
                                    }}
                                    onClick={() => handleAnswer(index, "no")}
                                >
                                    No
                                </button>
                            </div>
                        </>
                    ))}

                    <div className="btn-container" style={{ marginTop: "20px" }}>
                        <button onClick={handleYesToAll}>Yes to All</button>
                        <button onClick={handleNoToAll}>No to All</button>
                    </div>

                    <button onClick={handleSubmit}>Submit</button>
                </div>

                {warningModal && (
                    <div className="warning-modal-overlay">
                        <div className="back-button-container">
                            <div className="back-button" onClick={backHandle}>
                                Back
                            </div>
                        </div>

                        <div className="message-container">
                            <h2 className="warning-message" style={{ fontWeight: "500", fontSize: "1.8rem" }}>{warning.content}</h2>
                            <p style={{ marginTop: "30px", fontSize: "1.2rem" }}>For full terms and conditions <span style={{ color: "#f66d51", textDecoration: "underline" }}>click here</span></p>
                            <button onClick={handleWarningSubmit}>Continue</button>
                            <a
                                className="website-link"
                                href="https://www.orangesafepass.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ marginTop: "20px", fontSize: "20px" }}
                            >
                                www.orangesafepass.com
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
}
