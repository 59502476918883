import React, {useState, useEffect, useContext} from "react";
import makeRequest from "../../helpers/DataRequester";
import loadingGif from "../../assets/Loading.png";
import "../../styles/components/form.scss";

import {AppContext} from "../../helpers/AppContext";
import URL_ROUTES, {generateUrl} from "../../constants";
import FormData from "form-data";
import moment from 'moment-timezone';

export default function AddLocation({setLocations, setAdd, setDetails, setCsvContainer, setToggled}) {
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    const [warningMessage, setWarningMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [logoLoading, setLogoLoading] = useState(false);
    const {currentUser} = useContext(AppContext);
    const [timezones, setTimezones] = useState([]);

    // Function to populate timezones
    const populateTimezones = () => {
        const timezones = moment.tz.names();
        setTimezones(timezones);
    };

    const [formValues, setFormValues] = useState({
        admin: "",
        email: "",
        address: "",
        locationName: "",
        password: "",
        imageUrl: "",
        locationTimeZone: "",
        enableInduction: false
    });

    useEffect(() => {
        populateTimezones();
        const isFormValid = Object.values(formValues).every(value => value.trim() !== "" && value !== null);
        setWarningMessage(isFormValid ? "" : "Fill in all fields; logo is optional");
    }, [formValues]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormValues({...formValues, [name]: value});
    };
    function handleEnableInduction(e) {
        setFormValues({...formValues, enableInduction: e.target.checked})
    }

    const addNewLocation = async (event) => {
        event.preventDefault();
        setErrorMessage("");
        setLoading(true);
        const formData = {
            admin: formValues.admin,
            email: formValues.email === "" ? currentUser.email : formValues.email,
            address: formValues.address,
            locationName: formValues.locationName,
            password: formValues.password,
            imageUrl: formValues.imageUrl,
            locationTimeZone: formValues.locationTimeZone,
            enableInduction: formValues.enableInduction
        }

        const data = {
            uid: currentUser.uid,
            ...formData,
            url: formData.locationName.split(" ").join("").toLowerCase(),
            backUpUrl: formData.locationName.split(" ").join("").toLowerCase(),
        };

        makeRequest(URL_ROUTES.ADD_LOCATION, 'POST', {}, data, false).then((response) => {
            //clear form data
            setFormValues({
                admin: "",
                email: "",
                address: "",
                locationName: "",
                password: "",
                imageUrl: "",
                locationTimeZone: "",
                enableInduction: false
            });
            setInfoMessage(response.message);
        }).catch((error) => {
            console.log(error);
            setErrorMessage(error.message);
        }).finally(() => {
            setLoading(false);
        });
    };

    const uploadImage = async (e) => {
        //if formValues.locationName is empty, the image will not be uploaded
        if (formValues.locationName === "") {
            setErrorMessage("Please fill in the location name first")
            return;
        }
        setErrorMessage("")
        setLogoLoading(true);
        const image = e.target.files[0];
        const formData = new FormData();

        formData.append("image", image);
        formData.append("filename", formValues.locationName);

        try {
            const location = formValues.locationName;
            const url = generateUrl('UPLOAD_IMAGE', {name: location});
            const response = await makeRequest(url, "POST", {}, formData, false, true);

            setFormValues({...formValues, imageUrl: response.imageUrl});
        } catch (error) {
            console.error("Error uploading image:", error);
        }

        setLogoLoading(false);
    };

    return (
        <div className="add-container">
            <form className="form" name="add" onSubmit={addNewLocation}>
                <h2>Add new location</h2>

                <div className="input-wrapper">
                    <input
                        placeholder="Manager full name"
                        type="text"
                        id="admin"
                        name="admin"
                        value={formValues.admin}
                        onChange={handleInputChange}
                        autoComplete="off"
                        required
                    />
                </div>

                <div className="input-wrapper">
                    <input
                        placeholder="Location address"
                        type="text"
                        id="address"
                        name="address"
                        value={formValues.address}
                        onChange={handleInputChange}
                        autoComplete="off"
                        required
                    />
                </div>

                <div className="input-wrapper">
                    <input
                        placeholder="Location name"
                        type="text"
                        id="locationName"
                        name="locationName"
                        value={formValues.locationName}
                        onChange={handleInputChange}
                        autoComplete="off"
                        required
                    />
                </div>
                {/* Render email and password fields only for super_admin */}
                {currentUser.role === 'super_admin' && (
                    <>
                        <div className="input-wrapper">
                            <input
                                placeholder="Email"
                                type="email"
                                id="email"
                                name="email"
                                value={formValues.email}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                        </div>

                        <div className="input-wrapper">
                            <input
                                placeholder="Password"
                                type="password"
                                id="password"
                                name="password"
                                value={formValues.password}
                                onChange={handleInputChange}
                                autoComplete="off"
                                required
                            />
                        </div>
                    </>
                )}

                <div className="input-wrapper">
                    <input
                        className="input"
                        type="file"
                        id="file"
                        name="file"
                        onChange={uploadImage}
                        autoComplete="off"
                    />
                    <label className="file-label" htmlFor="file">
                        Upload logo
                    </label>
                </div>
                <div className="input-wrapper">
                    <select value={formValues.locationTimeZone} onChange={handleInputChange}
                            name="locationTimeZone">
                        <option value="">Select a timezone</option>
                        {timezones.map((timezone, index) => (
                            <option key={index} value={timezone}>
                                {timezone}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="induction-div">
                    <input
                        type="checkbox"
                        id="toggle-induction"
                        name="toggle-induction"
                        checked={formValues.enableInduction}
                        onChange={handleEnableInduction}
                        style={{marginTop: "10px"}}
                    />
                    <label className="add-printer-condition-label" htmlFor="add-induction">Enable Induction</label>
                </div>
                {logoLoading && (
                    <img
                        className="loading"
                        src={loadingGif}
                        alt="Loading is here"
                    />
                )}
                {loading && (
                    <img
                        style={{marginBottom: "0.7rem"}}
                        className="loading"
                        src={loadingGif}
                        alt="Loading is here"
                    />
                )}

                {formValues.imageUrl && (
                    <img
                        className="loading"
                        src={formValues.imageUrl}
                        alt="Logo is here"
                    />
                )}

                <p className="warning-message">{warningMessage}</p>
                <p className="error-message">{errorMessage}</p>
                {infoMessage && <p className="info-message">{infoMessage}</p>}

                <div className="btn-container">
                    <button
                        onClick={() => {
                            setAdd(false);
                            setLocations(true);
                            setCsvContainer(true);
                            setToggled(true);
                        }}
                    >
                        Cancel
                    </button>
                    <button type="submit">Save</button>
                </div>
            </form>
        </div>
    );
}
