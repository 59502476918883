import React, { useState } from "react";
import logo from "../assets/OrangePass-Logo.png";
import makeRequest from "../helpers/DataRequester";
import URL_ROUTES from "../constants";
import "../styles/components/form.scss";

export default function ResetPassword() {
    const [resetMessage, setResetMessage] = useState("");
    const [email, setEmail] = useState("");

    const resetPassword = async (event) => {
        event.preventDefault();
        setResetMessage("");

        try {
            const response = await makeRequest(URL_ROUTES.RESET_PASSWORD, "POST", {}, {email}, true);

            if (response.status === 200 || response.status === 404) {
                setResetMessage("Error sending reset link. Please make sure you have entered the correct email.");
            } else {
                setResetMessage("Please check your email for the reset link.");
            }
        } catch (error) {
            console.log("exception", { description: error.message });
            setResetMessage(error.message);
        }
    };

    return (
        <React.Fragment>
            <div className="logo-container">
                <a
                    href="https://www.orangesafepass.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img className="logo" src={logo} alt="logo" />
                </a>
            </div>
            <div className="main-container">
                <form className="form" name="login" onSubmit={resetPassword}>
                    <h2>Reset password</h2>

                    <div className="input-wrapper">
                        <input
                            placeholder="Registered email"
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off"
                            required
                        />
                    </div>
                    <p style={{ fontSize: "14px", marginTop: "10px" }} className="update-message">
                        {resetMessage}
                    </p>
                    <button type="submit" disabled={!email}>
                        Reset
                    </button>
                </form>
            </div>
        </React.Fragment>
    );
}
