import React, {useContext, Fragment, useState, useEffect, useRef} from "react";
import {AppContext} from "../../helpers/AppContext";
import makeRequest from "../../helpers/DataRequester";
import URL_ROUTES, {generateUrl} from "../../constants";
import {useLoading} from "../../helpers/LoadingContext";
import loadingGif from "../../assets/Loading.png";
import {AuthService} from "../../helpers/Auth";

export default function LocationDetails({setDetails, setEdit, setLocations, setAdd, setCsvContainer}) {
    const {chosenLocationId, LocationDetails, chosenLocationName} = useContext(AppContext);
    const [infoMessage, setInfoMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [logoLoading, setLogoLoading] = useState(false);
    const fileInputRef = useRef(null);
    const {setApiCallInProgress} = useLoading();
    const [showLocationSettingModal, setShowLocationSettingModal] = useState(false);
    const [showIPField, setShowIPField] = useState(LocationDetails?.settings?.hasPrinter ? true : false);
    const [rmeTitle, setRmeTitle] = useState(LocationDetails?.settings?.rmeTitle);
    const [rmeImageObj, setRmeImageObj] = useState({
        imageUrl: LocationDetails?.settings?.rmeImage,
        uploading: false
    });
    const [enableRme, setEnableRme] = useState(!!LocationDetails?.settings?.rmeEnabled);
    const [hasRmeSubscription, setHasRmeSubscription] = useState(LocationDetails?.settings?.hasRmeSubscription);
    const [IPAddress, setIPAddress] = useState(LocationDetails?.settings?.printerIPAddress);
    const [MacAddress, setMacAddress] = useState(LocationDetails?.settings?.printerMacAddress);

    const [turnRmeSubscription, setTurnRmeSubscription] = useState(LocationDetails?.settings?.hasRmeSubscription);

    const [qrCode, setQrCode] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        setShowIPField(LocationDetails?.settings?.hasPrinter ? true : false);
        setIPAddress(LocationDetails?.settings?.printerIPAddress);
        setMacAddress(LocationDetails?.settings?.printerMacAddress);
        setEnableRme(!!LocationDetails?.settings?.rmeEnabled);
        setRmeTitle(LocationDetails?.settings?.rmeTitle);
        setHasRmeSubscription(LocationDetails?.settings?.hasRmeSubscription);
        setTurnRmeSubscription(LocationDetails?.settings?.hasRmeSubscription);
    }, [LocationDetails]);

    function isUserSuperAdmin() {
        return AuthService.getUserDetails()?.claims?.role === "super_admin";
    }

    const toggleComponent = () => {
        setEdit(true);
        setAdd(false);
        setDetails(false);
        setLocations(false);
        setCsvContainer(false)
    };

    // const toggleLocationStatus = async () => {
    //     try {
    //         const response = await makeRequest(URL_ROUTES.TOGGLE_LOCATION_STATUS, "POST", {}, { chosenLocationId }, false);
    //         if (response.status === 200) {
    //             console.log("Location toggled");
    //             setDetails(false);
    //             setTimeout(function () {
    //                 window.location.reload();
    //             }, 500);
    //         } else {
    //             console.log(response.message);
    //         }
    //     } catch (error) {
    //         console.error("Error toggling status of location:", error);
    //     }
    // };

    const handleDelete = async () => {
        try {
            const response = await makeRequest(URL_ROUTES.DELETE_LOCATION, "DELETE", {}, {chosenLocationId}, false);
            if (response.status === 200) {
                console.log("Location deleted");
                setDetails(false);
                setTimeout(function () {
                    window.location.reload();
                }, 500);
            } else {
                console.log(response.message);
            }
        } catch (error) {
            console.error("Error deleting location:", error);
        }
    };

    /**
     * Generates a QR code for the chosen location
     * @returns {Promise<void>}
     */
    async function generateQRCode() {
        try {
            setApiCallInProgress(true);
            const response = await makeRequest(URL_ROUTES.GENERATE_QR, "POST", {}, {chosenLocationId});
            setQrCode(response);
            console.log("QR code generated", response);
        } catch (e) {
            console.error("Error generating QR code:", e);
        }
        setApiCallInProgress(false);
    }

    const handleImageChange = async (e) => {
        setLogoLoading(true);
        const selectedImage = e.target.files[0];
        setErrorMessage("")

        if (chosenLocationName && selectedImage) {
            try {
                const formData = new FormData();
                formData.append("image", selectedImage);
                let url = generateUrl('UPDATE_IMAGE', {name: chosenLocationName, locationId: chosenLocationId});
                // upload image
                const response = await makeRequest(url, 'POST', {}, formData, false, true);
                // Update the state with the new image URL
                setImageUrl(response.imageUrl);
                setInfoMessage(response.message);
            } catch (e) {
                console.error("Error uploading image:", e.message);
                setErrorMessage(e.message);
            } finally {
                setLogoLoading(false);
            }
        } else {
            setErrorMessage("Please fill in the location name or image first")
        }
    };

    async function uploadImage(e) {
        e.preventDefault();
        fileInputRef.current.click();
    };

    async function uploadRmImage(e) {
        e.preventDefault();
        fileInputRef.current.click();
    }

    const handleRMEImageChange = async (e) => {
        const selectedImage = e.target.files[0];
        setErrorMessage("")
        setRmeImageObj({
            imageUrl: null,
            uploading: true,
        });

        if (chosenLocationName && selectedImage) {
            try {
                const formData = new FormData();
                formData.append("image", selectedImage);
                let url = generateUrl('UPLOAD_IMAGE', {name: chosenLocationName, locationId: chosenLocationId});
                // upload image
                const response = await makeRequest(url, 'POST', {}, formData, false, true);
                // Update the state with the new image URL
                setRmeImageObj({
                    imageUrl: response.imageUrl,
                    uploading: false,
                });
            } catch (e) {
                console.error("Error uploading image:", e.message);
                setRmeImageObj({
                    imageUrl: null,
                    uploading: false,
                });
                setErrorMessage(e.message);
            }
        } else {
            setErrorMessage("Please fill in the location name or image first")
        }
    };

    async function saveSettings(e) {
        e.preventDefault();
        const settings = {
            printerIPAddress: IPAddress,
            hasPrinter: showIPField,
            printerMacAddress: MacAddress,
            rmeEnabled: enableRme,
            rmeTitle: rmeTitle,
            rmeImage: rmeImageObj.imageUrl,
            hasRmeSubscription: turnRmeSubscription
        }
        try {
            setApiCallInProgress(true);
            const response = await makeRequest(URL_ROUTES.SAVE_SETTINGS, "POST", {}, {
                chosenLocationId,
                settings
            }, false, false);
            setInfoMessage(response.message);
        } catch (e) {
            console.error("Error updating location settings:", e);
            setErrorMessage(e.message);
        }
        setApiCallInProgress(false);
        setShowLocationSettingModal(false);
    }

    /**
     * Handle the checkbox change event for printer
     * @param e
     */
    function handleAddPrinter(e) {
        setShowIPField(e.target.checked);
    }

    /**
     * Handle the checkbox change event for Rate My Experience
     * @param e
     */
    function handleAddRME(e) {
        setEnableRme(e.target.checked);
    }

    useEffect(() => {
        setQrCode('');
    }, [chosenLocationId]);

    return (
        <Fragment>
            {logoLoading && <img className="loading" src={loadingGif} alt="Loading is here"/>}

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {infoMessage && <p className="info-message">{infoMessage}</p>}
            <br/>
            <div className="details-action-container">
                <div className="location-details-container">
                    <div>
                        <div className="location-detail">
                            <p className="location-title">Manager Full Name:</p>
                            <p>{LocationDetails.admin}</p>
                        </div>
                        <div className="location-detail">
                            <p className="location-title">Location Address:</p>
                            <p>{LocationDetails.address}</p>
                        </div>
                        <div className="location-detail">
                            <p className="location-title">Location Name:</p>
                            <p>{LocationDetails.name}</p>
                        </div>
                        {LocationDetails.locationTimeZone && (
                            <div className="location-detail">
                                <p className="location-title">TimeZone:</p>
                                <p>{LocationDetails.locationTimeZone}</p>
                            </div>
                        )}
                        {/* <div className="location-detail">
                            <p className="location-title">Login email:</p>
                            <p>{LocationDetails.email}</p>
                        </div>
                        <div className="location-detail">
                            <p className="location-title">Location status:</p>
                            {LocationDetails.status ? <p>Active</p> : <p>Deactive</p>}
                        </div> */}
                        <div className="location-detail">
                            <p className="location-title">Application link:</p>
                            <p>{process.env.REACT_APP_URL}/{LocationDetails.backUpUrl}/pass</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="details-btn-container">
                <div>
                    <button className="edit-btn" onClick={() => toggleComponent()}>
                        Edit
                    </button>
                    <button
                        className="delete-btn"
                        onClick={() => {
                            if (window.confirm("Are you sure to delete this location?"))
                                handleDelete();
                        }}
                    >
                        Delete
                    </button>
                </div>
                <div className='portal-right-side-button '>
                    {!LocationDetails?.enableInduction && <button
                        className="portal-btn-generateQR"
                        onClick={() => setShowLocationSettingModal(true)}
                    >
                        Location Settings
                    </button>}
                    <button
                        className="portal-btn-generateQR"
                        onClick={generateQRCode}
                    >
                        {qrCode ? 'Re-generate QR' : 'Generate QR'}
                    </button>
                    {/* image upload */}
                    <div>
                        <input
                            ref={fileInputRef}
                            type="file"
                            name="image"
                            id="image"
                            onChange={handleImageChange}
                            required
                            style={{display: "none"}}
                        />
                        <button
                            onClick={uploadImage}
                            className="portal-btn-generateQR"
                        >
                            Upload logo
                        </button>
                    </div>
                </div>

                {/*{LocationDetails.status ? (*/}
                {/*    <button*/}
                {/*        className="deactive-btn"*/}
                {/*        onClick={() => {*/}
                {/*            if (window.confirm("Are you sure to deactivate this location?"))*/}
                {/*                toggleLocationStatus();*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Deactivate*/}
                {/*    </button>*/}
                {/*) : (*/}
                {/*    <button*/}
                {/*        className="active-btn"*/}
                {/*        onClick={() => {*/}
                {/*            if (window.confirm("Are you sure to activate this location?"))*/}
                {/*                toggleLocationStatus();*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        Activate*/}
                {/*    </button>*/}
                {/*)}*/}
            </div>
            {/* div to display image */}
            <div className="details-action-container"
                 style={{display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px"}}>
                <div className="image-holder" style={{height: "200px"}}>
                    {LocationDetails?.logo &&
                        <img src={LocationDetails.logo} alt="location" style={{height: "200px"}}/>}
                    {
                        /**
                         * display qr code from base64 string
                         */
                        qrCode && <img src={qrCode} alt="QR" style={{height: "200px"}}/>
                    }
                </div>
            </div>
            {
                /*Add Printer Modal*/
            }
            {showLocationSettingModal && <div className="add-printer-overlay">
                <div className="back-button-container">
                    <button className="back-button" onClick={() => setShowLocationSettingModal(false)}>Close</button>
                </div>
                <form className="form settings-form" name="settings-form" onSubmit={saveSettings}>
                    <div className="settings-div">
                        <input
                            type="checkbox"
                            id="add-printer"
                            name="add-printer"
                            checked={showIPField}
                            onChange={handleAddPrinter}
                            style={{marginTop: "10px"}}
                        />
                        <label className="add-printer-condition-label" htmlFor="add-printer">Enable Pass Print</label>
                        {showIPField && <>
                            <div className="input-wrapper">
                                <input
                                    placeholder="IP Address"
                                    type="text"
                                    id="ip-address"
                                    name="ip-address"
                                    autoComplete="off"
                                    value={IPAddress}
                                    onChange={(e) => setIPAddress(e.target.value)}
                                    required={showIPField}
                                />
                            </div>
                            <div className="input-wrapper">
                                <input
                                    placeholder="MAC Address"
                                    type="text"
                                    id="mac-address"
                                    name="mac-address"
                                    value={MacAddress}
                                    autoComplete="off"
                                    onChange={(e) => setMacAddress(e.target.value)}
                                    required={showIPField}
                                />
                            </div>
                        </>}
                        <br/>
                        {
                            isUserSuperAdmin() && <>
                                <input
                                    type="checkbox"
                                    id="toggle-rme-subscription"
                                    name="toggle-rme-subscription"
                                    checked={turnRmeSubscription}
                                    onChange={() => setTurnRmeSubscription(!turnRmeSubscription)}
                                    style={{marginTop: "10px"}}
                                />
                                <label className="add-printer-condition-label" htmlFor="toggle-rme-subscription">Enable
                                    Rate My Experience Subscription</label>
                            </>
                        }
                        <br/>
                        {hasRmeSubscription && !isUserSuperAdmin() && <><input
                            type="checkbox"
                            id="toggle-rme"
                            name="toggle-rme"
                            checked={enableRme}
                            onChange={handleAddRME}
                            style={{marginTop: "10px"}}
                        />
                            <label className="add-printer-condition-label" htmlFor="toggle-rme">Enable Rate My
                                Experience</label>
                            {enableRme && <>
                                <div className="input-wrapper">
                                    <input
                                        placeholder="Title"
                                        type="text"
                                        id="rme-title"
                                        name="rme-title"
                                        value={rmeTitle}
                                        autoComplete="off"
                                        onChange={(e) => setRmeTitle(e.target.value)}
                                        required={enableRme}
                                    />

                                </div>
                                <div className="input-wrapper">
                                    <div>
                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            name="rme-image"
                                            id="rme-image"
                                            onChange={handleRMEImageChange}
                                            required
                                            style={{display: "none"}}
                                        />
                                        {rmeImageObj.uploading && <p className="info-message">Uploading Image</p>}
                                        <button
                                            onClick={uploadImage}
                                            className="portal-btn-generateQR"
                                        >
                                            {
                                                rmeImageObj?.uploading ? "Uploading..." : "Upload Background"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </>
                            }</>}
                    </div>
                    <button type="submit" className="settings-save-btn" disabled={rmeImageObj?.uploading}>
                        Save
                    </button>
                </form>
            </div>}
        </Fragment>
    );
}
