import React, { useContext, useState, useEffect, Fragment } from "react";
import { AppContext } from "../../helpers/AppContext";
import loadingGif from "../../assets/Loading.png";
import { uid } from "uid";
import makeRequest from "../../helpers/DataRequester";
import URL_ROUTES from "../../constants";

export default function RulesEdit() {
    const { chosenLocationId, chosenLocationName } = useContext(AppContext);
    const [rules, setRules] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [infoMessage, setInfoMessage] = useState("");
    const [warningMessage, setWarningMessage] = useState("");

    useEffect(() => {
        const fetchRules = async () => {
            if (chosenLocationId !== "") {
                setLoading(true);
                try {
                    let url = `${URL_ROUTES.GET_COLLECTION}?locationId=${chosenLocationId}&collectionType=rules`;
                    const response = await makeRequest(url, "GET");
                    setRules(response);
                    setIsButtonDisabled(false);
                } catch (error) {
                    setInfoMessage(error.message);
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchRules();
    }, [chosenLocationId]);

    const handleChange = (value, id) => {
        const updatedRules = rules.map((rule) => {
            if (rule.id === id) {
                return { ...rule, content: value };
            }
            return rule;
        });
        setRules(updatedRules);
    };

    const handleUpload = async () => {
        const updatedEmptyRules = rules.filter((rule) => rule.content === "");
        const updatedRules = rules.filter((rule) => rule.content !== "");

        if (updatedEmptyRules.length > 0) {
            setWarningMessage("Rule is required.");
            return;
        }

        try {
            setLoading(true);
            let url = `${URL_ROUTES.SAVE_TO_COLLECTION}?locationId=${chosenLocationId}&collectionType=rules`;
            const response = await makeRequest(url, "POST", {}, { dataList: updatedRules }, false);
            setInfoMessage(response.message);
        } catch (error) {
            console.error(error);
            setInfoMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        setLoading(true);
        const updatedRules = rules.filter((rule) => rule.id !== id);
        setRules(updatedRules);
        try {
            let url = `${URL_ROUTES.DELETE_FROM_COLLECTION}?locationId=${chosenLocationId}&collectionType=rules`;
            const response = await makeRequest(url, "DELETE", {}, { collectionId: id }, false);
            setInfoMessage(response.message);
        } catch (error) {
            console.error(error);
            setInfoMessage(error.message);
        } finally {
            setLoading(false);
        }
    };

    const createEditBox = () => {
        const newRule = {
            content: "",
            id: uid(20),
        };
        setRules([...rules, newRule]);
    };

    return (
        <Fragment>
            <div className="components-container">
                <div className="csv-container">
                    <div>
                        <h2>Review Rules</h2>
                        <p className="location-name">{chosenLocationName}</p>
                        {infoMessage && <p className="info-message">{infoMessage}</p>}
                    </div>
                    {loading && <img className="loading" src={loadingGif} alt="Loading is here" />}
                    <div>
                        <button className="add-btn" onClick={createEditBox} disabled={isButtonDisabled}>
                            Add
                        </button>
                        <button className="save-btn" onClick={handleUpload} disabled={isButtonDisabled}>
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <div className="edit-container">
                {rules.map((rule) => (
                    <div key={rule.id}>
                        <div className="textarea-container">
                            <textarea
                                placeholder="Type the rule and click save"
                                name="rule"
                                value={rule.content}
                                onChange={(e) => handleChange(e.target.value, rule.id)}
                            />
                            <div>
                                <button
                                    className="delete-btn"
                                    onClick={() => {
                                        if (window.confirm("Are you sure to delete this rule?")) {
                                            handleDelete(rule.id);
                                        }
                                    }}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                        {warningMessage !== "" && rule.content.trim() === "" && (
                            <p className="warning-message">{warningMessage}</p>
                        )}
                    </div>
                ))}
            </div>
        </Fragment>
    );
}
